<template>
  <div id="app">
    <HeaderView />
    <BodyView>
      <router-view />
    </BodyView>
    <FooterView />
  </div>
</template>

<script>
import HeaderView from './components/HeaderView.vue'
import FooterView from './components/FooterView.vue'
import BodyView from './components/BodyView.vue'

export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView,
    BodyView
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>