<template>
  <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <!-- 추가적인 네비게이션 링크 -->
  </nav>
</template>

<script>
export default {
  name: 'NavigationView'
}
</script>

<style scoped>
nav {
  display: flex; /* 가로로 배치 */
  justify-content: center; /* 가운데 정렬 */
  margin: 1rem 0;
}

nav a {
  margin: 0 1rem;
  color: #fff;
  text-decoration: none;
  font-weight: bold; /* 강조된 텍스트 */
  padding: 0.5rem 1rem; /* 클릭 영역 확대 */
  border-radius: 5px; /* 둥근 모서리 */
  transition: background-color 0.3s ease; /* 부드러운 배경색 변화 */
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.2); /* hover 시 배경색 변경 */
}

.router-link-active {
  color: #4db8ff; /* 현재 활성화된 링크의 색상 */
}
</style>