<template>
    <main>
      <slot></slot>
    </main>
  </template>
  
  <script>
  export default {
    name: 'BodyView'
  }
  </script>
  
  <style scoped>
  main {
    padding: 2rem;
    background-color: #f9f9f9;
  }
  </style>  