<template>
  <header>
    <h1>(주)반석종합부동산</h1>
    <Navigation /> <!-- Navigation 컴포넌트를 포함 -->
  </header>
</template>
  
<script>
  import Navigation from './NavigationView.vue'
  
  export default {
    name: 'HeaderView',
    components: {
      Navigation
    }
  }
</script>
  
<style scoped>
  header {
    background-color: #333;
    color: white;
    padding: 1rem;
    text-align: center;
  }
</style>  