<template>
  <footer>
    <p>
      &copy; 2020 (주)반석종합부동산. All rights reserved. | 
      <a href="/about" aria-label="회사 소개로 이동">회사 소개</a> | 
      Tel: 010-2131-3090 | 
      Email: <a href="mailto:13-20303@naver.com" aria-label="이메일 보내기">13-20303@naver.com</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>

<style scoped>
footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center; /* 내용 가운데 정렬 */
  flex-wrap: wrap; /* 줄바꿈을 허용하여 작은 화면에서 대응 */
  gap: 0.5rem; /* 각 항목 사이의 간격 */
  font-size: 0.9rem; /* 푸터 폰트 크기 약간 축소 */
}

footer p {
  margin: 0;
  display: flex;
  flex-wrap: wrap; /* 줄 바꿈 가능하게 설정 */
  justify-content: center; /* 작은 화면에서 가운데 정렬 */
}

footer a {
  color: #4db8ff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline; /* 마우스 올렸을 때 밑줄 */
}

/* 작은 화면에서 폰트 크기와 패딩 조정 */
@media (max-width: 600px) {
  footer {
    padding: 0.5rem;
    font-size: 0.8rem; /* 폰트 크기 축소 */
  }
}
</style>