<template>
    <div>
      <h2>Home Page</h2>
      <p>Welcome to the home page!</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeView'
  }
  </script>  