<template>
  <div class="about-container">
    <h2>회사 소개</h2>
    <p>
      (주)반석종합부동산은 부동산 관리업을 전문으로 하는 회사입니다.
      저희는 고객의 자산을 체계적이고 효율적으로 관리하여, 안정적인 임대 수익을 보장해드립니다.
    </p>

    <h3>업무 범위</h3>
    <ul>
      <li>임대관리</li>
      <li>퇴실 후, 방 상태 점검 및 공과금 정산</li>
      <li>건물 공용시설 정기청소</li>
      <li>그 외 임대인이 요청하는 사항(도배, 전등교체 등)</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AboutView'
}
</script>

<style scoped>
.about-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto; /* 가운데 정렬 */
}

h2, h3 {
  color: #333;
  margin-bottom: 1rem;
}

p {
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  line-height: 1.6;
}

li {
  margin-bottom: 0.5rem;
}
</style>